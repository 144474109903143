import slick from "slick-carousel";
import fancybox from "@fancyapps/fancybox";
import { popover, tooltip } from "bootstrap";
import cookie from "jquery.cookie";
import Masonry from "masonry-layout";

const imagesLoaded = require('imagesloaded');

let masonryItem = document.querySelector(".masonry");
if (masonryItem) {
    imagesLoaded(masonryItem, function (instance) {
        let masonry = new Masonry(masonryItem, {
            itemSelector: ".masonry__item",
            percentPosition: true,
        });
    });

}


$(document).ready(function () {
    /* slider */
    $("#slider").slick({
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        prevArrow: '<div class="prev"><i class="material-icons">navigate_before</i></div>',
        nextArrow: '<div class="next"><i class="material-icons">navigate_next</i></div>',
        dots: true,
        centerMode: true,
        fade: true,
    });

    /* 
     * menu link active helper
     */
    $(".navbar .dropdown-item.active").parents("li").addClass("active");
    $(".navi-item-link.active").parents("li").children("a").addClass("active");

    /* popover
     *  
     */
    $(function () {
        $('[data-toggle="popover"]').popover()
    });

    /* FANCYBOX */
    $("a.fancybox").fancybox({
        loop: true,
        keyboard: true,
    });

    /* COOKIE */
    if ($("#cookieInfo").length) {
        var cookie = $.cookie("cookieinfo");
        if (cookie === '0') {
            $("#cookieInfo .cookieClose").on('click', function () {
                $.cookie("cookieinfo", '1', { path: '/', expires: 3650 });
                $("#cookieInfo").animate({
                    'margin-top': - $("#cookieInfo").outerHeight(true)
                }, 800, function () {
                    $("#cookieInfo").remove();
                });

            })
        }
    }

    $(".navbar-toggler").click(function () {
        if ($(this).hasClass("collapsed")) {
            $(this).removeClass("collapsed");
            $(".mainnav").addClass("hover");
        } else {
            $(this).addClass("collapsed");
            $(".mainnav").removeClass("hover");
        }
    })

    $(window).on("reize load", function () {
        if ($(window).width() < 767) {
            $(".navi-item.hassub > a").on("click", function (e) {
                e.preventDefault();
                if ($(this).parent().hasClass("hover")) {
                    $(this).parent().removeClass("hover");
                } else {
                    $(this).parent().addClass("hover");
                }
            })
        }
    })
    $(window).on("scroll", function () {
        if ($("body").scrollTop() > 162) {
            $(".mainnav").addClass("mainnav-fixed");
        } else {
            $(".mainnav").removeClass("mainnav-fixed");
        }
    });

    // mobile menu
    $("#openMenu").click(function () {
        $(".mobilenav").addClass("mobilenav--visible");
    });
    $("#closeMobileNav").click(function () {
        $(".mobilenav").removeClass("mobilenav--visible");
    });
    $(".mobilenav__link--sub-open").click(function (e) {
        e.preventDefault();
        $(this).parent().find(".mobilenav__sub").addClass("mobilenav__sub--visible");
    });
    $(".mobilenav__link--sub-close").click(function (e) {
        $(".mobilenav__sub").removeClass("mobilenav__sub--visible");
    });
    $(".mobilenav__link--sub2-open").click(function (e) {
        e.preventDefault();
        $(this).parent().find(".mobilenav__sub2").addClass("mobilenav__sub2--visible");
    });
    $(".mobilenav__link--sub2-close").click(function (e) {
        e.preventDefault();
        $(".mobilenav__sub2").removeClass("mobilenav__sub2--visible");
    });

});
